import { useSelector } from "react-redux";
import styled from "styled-components";
import { BackToTopIcon } from "../../assets/icons/BackToTopIcon";


function BackToTopComponent(props) {
  const themeConfigReduxState = useSelector((state) => state?.session?.themeConfig);
  const config = themeConfigReduxState;
  const BackToTopStyled = styled.div`
    position: fixed;
    bottom: 32px;
    right: 32px;
  `;
  const colorGroupsDefault = config?.general?.color?.colorGroups;
  const currentColor = colorGroupsDefault?.length > 0 ? colorGroupsDefault[0] : {};

  return (
    <BackToTopStyled
      onClick={(e) => {
        const element = document.getElementById("themeHeader");
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }}
      className="pointer d-none"
      id="back2Top"
    >
      <BackToTopIcon color={currentColor?.buttonTextColor} backgroundColor={currentColor?.buttonBackgroundColor} />
    </BackToTopStyled>
  );
}

export default BackToTopComponent;
