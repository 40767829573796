export const EnumLogin = {
  LOGIN: 1,
  OTP: 2,
  SUCCESS: 3,
  INPUT_INFO: 4,
  REGISTER: 5,
};

export const KEYBOARD = {
  ENTER: "Enter",
};
