import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { HomeIcon, LocationAddressIcon, OfficeIcon } from "../../assets/icons.constants";
import DeliveryAddressLocationIcon from "../../assets/icons/delivery-address-location-icon";
import { default as DeliveryAddressLeftArrowIcon } from "../../assets/icons/delivery-address-location-left-arrow-icon";
import DeliveryAddressRightArrowIcon from "../../assets/icons/delivery-address-location-right-arrow-icon";
import { EnumCustomerAddressType } from "../../constants/enum";
import { StoreBranchAddressSelector } from "../store-branch-address-selector/store-branch-address-selector.component";
import "./customer-address.style.scss";

export const CustomerAddresses = forwardRef((props, ref) => {
  const themeConfigReduxState = useSelector((state) => state?.session?.themeConfig);
  const {
    addressList,
    setCustomerAddress,
    branchSelected,
    customerAddress,
    hasCustomerAddresses,
    storeBranchAddressSelectorRef,
    openStoreBranchAddressSelector,
    deliveryAddress,
    onSelectStoreBranchAddress,
    setOpenStoreBranchAddressSelector,
  } = props;
  const [t] = useTranslation();
  const config = themeConfigReduxState;
  const colorGroupFirstDefault = config?.general?.color?.colorGroups[0];
  const [isSelectBranch, setIsSelectBranch] = useState(false);
  const translateData = {
    myAddress: t("storeWebPage.profilePage.myAddress", "ĐỊA CHỈ CỦA BẠN"),
    default: t("storeWebPage.profilePage.default", "Mặc định"),
    selectOtherBranch: t("storeWebPage.profilePage.selectOtherBranch", "Chi nhánh khác"),
    deliveryFrom: t("storeWebPage.profilePage.deliveryFrom", "Được giao từ"),
  };

  useEffect(() => {}, []);

  useImperativeHandle(ref, () => ({}));

  const StyledAddressSection = styled.div`
    .my-address-container {
      .address-list-container {
        .address-detail-box {
          border-color: ${colorGroupFirstDefault?.buttonBorderColor};
          .right-content {
            .address-title,
            .address-detail-text {
              color: #282828;
            }
            .address-default {
              .address-default-text {
                color: ${colorGroupFirstDefault?.buttonBackgroundColor};
                border: 1px solid ${colorGroupFirstDefault?.buttonBackgroundColor};
              }
            }
          }
        }
      }
    }
  `;
  const handleClickChangeBranch = () => {
    setIsSelectBranch(!isSelectBranch);
  };
  return (
    <div>
      <div className={`store-branch-component ${isSelectBranch ? "show" : ""}`}>
        <div
          className="header-change-branch"
          onClick={() => {
            handleClickChangeBranch();
          }}
        >
          <DeliveryAddressLeftArrowIcon />
          <span className="header">{translateData?.selectOtherBranch} </span>
        </div>
        <StoreBranchAddressSelector
          ref={storeBranchAddressSelectorRef}
          open={openStoreBranchAddressSelector}
          initStoreBranchData={branchSelected ?? deliveryAddress?.branchAddress}
          onSelected={(branch, isSelectNewBranch) => {
            onSelectStoreBranchAddress(branch, isSelectNewBranch, true);
            if (isSelectBranch) {
              setIsSelectBranch(!isSelectBranch);
            }
          }}
          onClose={() => setOpenStoreBranchAddressSelector(false)}
        />
      </div>
      <div className={`address-component ${isSelectBranch ? "" : "show"}`}>
        {hasCustomerAddresses && (
          <div className="customer-delivery-address-wrapper">
            <StyledAddressSection>
              <div className="my-address-container">
                <div className="title-container">
                  <span className="title-text">{translateData.myAddress}</span>
                </div>
                <div className="address-list-container">
                  {addressList?.map((item, index) => {
                    return (
                      <div className="address-detail-box" onClick={() => setCustomerAddress(item)}>
                        <div className="left-content">
                          {item?.customerAddressTypeId === EnumCustomerAddressType.Home ? (
                            <HomeIcon />
                          ) : item?.customerAddressTypeId === EnumCustomerAddressType.Work ? (
                            <OfficeIcon />
                          ) : (
                            <LocationAddressIcon />
                          )}
                        </div>
                        <div className="right-content">
                          <span className="address-title">{item?.name}</span>
                          <br />
                          <span className="address-detail-text">{item?.address}</span>
                          {item?.isDefault && (
                            <div className="address-default">
                              <span className="address-default-text">{translateData.default}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </StyledAddressSection>
          </div>
        )}
        <div
          className="header-change-branch"
          onClick={() => {
            handleClickChangeBranch();
          }}
        >
          <span className="header">{translateData?.deliveryFrom} </span>
          <DeliveryAddressRightArrowIcon />
        </div>
        <div className="address-store-picked">
          <div className="logo">
            <DeliveryAddressLocationIcon fillColor={colorGroupFirstDefault?.buttonBackgroundColor} />
            <span className="outline" style={{ background: colorGroupFirstDefault?.buttonBackgroundColor }}>
              <span className="address-index"></span>
            </span>
          </div>

          <div className="store-infomation">
            <div className="store-title">
              <span>{branchSelected?.title} </span>
            </div>
            <div className="store-content">
              <span>{branchSelected?.addressDetail}</span>
            </div>
            <span className="distance" style={{ color: colorGroupFirstDefault?.buttonBackgroundColor }}>
              {branchSelected?.distance}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});
