export const amountMaximumOfBanner = 5;
export const DateFormat = {
  DD_MM_YYYY: "DD/MM/YYYY",
  YYYY_MM_DD: "YYYY/MM/DD",
  MM_DD_YYYY: "MM/DD/YYYY",
  HH_MM_DD_MM_YYYY: "hh:mm A DD/MM/yyyy",
  DD_MM_YYYY_HH_MM_SS: "DD/MM/YYYY, hh:mm:ss",
  DD_MM_YYYY_DASH: "DD-MM-YYYY",
  DDMMYYYYHHmmss: "DDMMYYYYHHmmss",
  YYYY_MM_DD_HH_MM_SS: "yyyy-MM-DD HH:mm:ss",
  HH_MM: "HH:mm",
  HH_MM_2: "HH:MM",
  HH: "HH",
  MM: "mm",
  HH_MM_DD_MM_YYYY_: "HH:mm DD/MM/yyyy",
  YYYY_MM_DD_HH_MM: "yyyy-MM-DD HH:mm",
  DD_MM_YYYY_HH_MM: "DD/MM/YYYY hh:mm",
  DD_MM_YYYY_HH_MM_2: "DD/MM/YYYY, HH:mm",
  DD_MM_YYYY_HH_MM_3: "DD/MM/YYYY HH:mm",
  DD_MM_YYYY_HH_MM_SS_: "DD/MM/YYYY HH:mm:ss",
  HH_MM__DD_MM_YYYY_: "HH:mm - DD/MM/yyyy",
  DD_MM_YYYY_HH_mm: "DD-MM-YYYY HH:mm",
  MMMM_YYYY: "MMMM YYYY",
  YYYY_MM_DD_2: "YYYY-MM-DD",
  YYYY_MM_DD_HH_MM: "YYYY-MM-DD HH:mm",
  YYYY_MM_DDTHH_mm_ss_SSSZ: "YYYY-MM-DDTHH:mm:ss.SSSZ",
};

export const currency = {
  vnd: "VND",
  d: "đ",
};

export const ValidTimeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export const maximumQuantity = 999;

export const profileTab = {
  myAccount: "1",
  myAddress: "2",
  myOrder: "3",
  loyaltyPoint: "4",
  myReservation: "5",
};

export const ImageSizeDefault = 5242880;

export const maximumTotalAmountSpent = 999999999;

export const ThemeKey = "46565f44-c3e2-449d-8d58-3850a95ffba7";

export const PHONE_NUMBER_REGEX = /^[0-9]{9,12}\s*?$/;

export const ScrollHeaderType = {
  SCROLL: 1,
  FIXED: 2,
};

export const intervalTime = 15;

export const pathNameMyOrderDetail = "my-profile";

export const NONE_APPLICABLE = "N/A";
