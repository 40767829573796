import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { FontFamilyStoreApp } from "./constants/font-family.constants";
import storeDataService from "./data-services/store-data.service";
import { setCartItems, setStoreConfig, setThemeCustomizeConfig } from "./modules/session/session.actions";
import { setThemeConfig } from "./modules/theme-customize/theme-customize.actions";
import AppProvider from "./providers/app.provider";
import themeConfigService, { addFont } from "./services/theme-config.services";
import { defaultRouters } from "./theme/app/router";
import { themeData, themeToken } from "./theme/theme.data";
import { getStorage, localStorageKeys, setStorage } from "./utils/localStorage.helpers";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function App(props) {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [currentThemeConfig, setCurrentThemeConfig] = useState(null);
  const themeConfig = useSelector((state) => state.session?.themeConfig);
  const [fontFamily, setFontFamily] = useState(themeToken.fontFamily);
  const initTempData = () => {
    let jsonCartItems = localStorage.getItem(localStorageKeys.STORE_CART);
    if (jsonCartItems === null || jsonCartItems === "undefined") {
      jsonCartItems = "[]";
      localStorage.setItem(localStorageKeys.STORE_CART, jsonCartItems);
    }
    const cartItems = JSON.parse(jsonCartItems);
    dispatch(setCartItems(cartItems));
  };
  useEffect(() => {
    initTempData();
    readConfigFromConfigFile();
    getStoreConfig();
  }, []);

  const getStoreConfig = async () => {
    //Fetch Data Store Config
    try {
      const currentStoreId =
        themeConfig?.storeId ?? JSON.parse(localStorage?.getItem(localStorageKeys.STORE_CONFIG))?.storeId;
      if (currentStoreId) {
        const resStoreConfig = await storeDataService.getStoreConfig(currentStoreId);
        if (resStoreConfig) {
          dispatch(setStoreConfig(resStoreConfig?.data?.storeConfig));
        }
      }
    } catch {}
  };

  const readConfigFromConfigFile = () => {
    fetch(`${process.env.PUBLIC_URL}/store.config.json`)
      .then((response) => {
        return response.json();
      })
      .then(async (data) => {
        const jsonData = JSON.stringify(data);
        const version = data?.lastUpdateDate ?? "1.0.0";
        const defaultLanguage = data?.defaultLanguage ?? "vi";
        const oldVersion = getStorage(localStorageKeys.VERSION);
        if (oldVersion !== version) {
          // Remove old value if new version updated
          localStorage.removeItem(localStorageKeys.STORE_CART);
        }
        if (!localStorage.getItem("i18nextLng")) {
          setStorage(localStorageKeys.I18NEXTLNG, defaultLanguage);
        }
        setStorage(localStorageKeys.VERSION, version);
        setStorage(localStorageKeys.STORE_CONFIG, jsonData);
        setCurrentThemeConfig(data);
        dispatch(setThemeCustomizeConfig(data));

        if (Boolean(window.isStoreAppWebView)) {
          addFont(FontFamilyStoreApp.path);
          setFontFamily(FontFamilyStoreApp.name);
        } else {
          const font = themeConfigService.getConfigsByKey(["general", "font"]);
          if (font) {
            addFont(font.path);
            setFontFamily(font.name);
          }
        }

        // REFACTOR
        dispatch(setThemeConfig(data)); // storage theme json config on initial state
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const routers = useMemo(() => {
    let routes = themeData.pages?.map((p) => {
      return {
        id: p.id,
        name: p.name,
        component: p.component,
        path: p?.path,
      };
    });

    // add default route
    routes.push({
      ...themeData.pages[0],
      path: "/",
    });
    routes = routes.concat(defaultRouters);
    return routes;
  }, []);

  if (!currentThemeConfig) return null;

  return (
    <AppProvider fontFamily={fontFamily}>
      <Helmet>
        <meta content={themeConfig["general"]["header"]["logoUrl"]} itemprop="image" />
      </Helmet>
      <Router>
        <Switch>
          {routers.map((route) => {
            const { component: Component, id, path, name, ...rest } = route;
            return (
              <Route
                {...rest}
                key={id}
                path={path}
                render={(props) => <Component {...props} pageName={t(name)} pageId={id} fontFamily={fontFamily} />}
                exact={true}
              />
            );
          })}
        </Switch>
      </Router>
    </AppProvider>
  );
}

export default App;
